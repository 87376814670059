.profile {
  &.-mgb {
    margin-bottom: 5rem;
  }
  &__heading {
    font-size: 3rem;
    text-align: center;
  }
  &__avatar {
    border-radius: 50%;
  }
}