@mixin fz($size: 16) {
  font-size: ($size / 10) + rem;
}
html {
  font-size: 62.5%;
}
.container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.App-header {
  .App-link {
    @include fz(30);
    color: #61dafb;
    line-height: 2;
    display: flex;
    align-items: center;
    > svg {
      padding-right: .6rem;
    }
  }
}